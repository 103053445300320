import "./BrandSection.scss";
import { useRef, useEffect } from "react";

function BrandSection({ title, logos }) {
  const scrollContainerRef = useRef(null);
  const scrollImgsRef = useRef(null);
  const animationIdRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const scrollImgs = scrollImgsRef.current;

    if (scrollContainer && scrollImgs) {
      // Duplicate logos for seamless scrolling
      const clonedLogos = scrollImgs.cloneNode(true);
      scrollContainer.appendChild(clonedLogos);

      let scrollAmount = 0;
      const speed = 1; // Adjust speed for smoother scrolling

      // Scroll function using requestAnimationFrame
      const scrollLogos = () => {
        scrollAmount -= speed;

        // If we've scrolled past the width of the logos, reset scroll
        if (Math.abs(scrollAmount) >= scrollImgs.offsetWidth) {
          scrollAmount = 0;
        }

        // Apply the scroll using margin-left
        scrollImgs.style.marginLeft = `${scrollAmount}px`;
        // clonedLogos.style.marginLeft = `${scrollAmount}px`;

        // Continue the loop
        animationIdRef.current = requestAnimationFrame(scrollLogos);
      };

      // Start the scroll loop
      animationIdRef.current = requestAnimationFrame(scrollLogos);

      // Cleanup function to cancel the animation when the component unmounts
      return () => cancelAnimationFrame(animationIdRef.current);
    }
  }, [logos]);

  return (
    <section className="brand-section w-full h-15rem py-5">
      <h3 className="poppins-regular w-full text-xl md:text-4xl">{title}</h3>
      <div className="brands-logo w-full h-6rem px-6 overflow-hidden">
        <div className="scroll-container" ref={scrollContainerRef}>
          <div className="scroll-imgs" ref={scrollImgsRef}>
            {/* Original logos */}
            {logos.map((logo, index) => (
              <div className="logo-item" key={`brand${index}`}>
                <img
                  src={logo.img}
                  alt="partners brands logos"
                  style={{
                    ...logo,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default BrandSection;
